import { CREATE_ORDER_ITEM_KEY } from '../../../hooks/api/order/item/useOrderItemCreate'
import { toast } from 'react-toastify'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useIsFetching, useIsMutating } from '@tanstack/react-query'
import ThemeContext from '../../../contexts/ThemeContext'

const EXCLUDED_QUERY_KEYS = ['selectFieldPaginateLoadOptions']

const LoadingToast = () => {
    const { loading: isLoadingTheme } = useContext(ThemeContext)
    const fetching = useIsFetching({
        predicate: query => !EXCLUDED_QUERY_KEYS.includes(query.queryKey[0] as string)
    })

    const mutating = useIsMutating({
        predicate: ({ options: { mutationKey } = {} }) => {
            if (Array.isArray(mutationKey)) {
                return (
                    !mutationKey.includes(CREATE_ORDER_ITEM_KEY) &&
                    !EXCLUDED_QUERY_KEYS.includes(mutationKey[0] as string)
                )
            }
            return (mutationKey as unknown as string) !== CREATE_ORDER_ITEM_KEY
        }
    })

    const isLoading = useMemo<boolean>(() => {
        return !!fetching || !!mutating || isLoadingTheme
    }, [fetching, mutating, isLoadingTheme])
    const [, setLoadingDismissTimeout] = useState<NodeJS.Timeout>()

    useEffect(() => {
        if (isLoading) {
            //Cancel previous toast dismiss
            setLoadingDismissTimeout(timeoutId => {
                if (timeoutId) {
                    clearTimeout(timeoutId)
                }
                return undefined
            })
            toast.loading('Loading...', { toastId: 'loading', position: 'bottom-right' })
        } else {
            //Delayed toast dismiss
            setLoadingDismissTimeout(
                setTimeout(() => {
                    setLoadingDismissTimeout(undefined)
                    toast.dismiss('loading')
                }, 1600)
            )
        }
        return () => {
            toast.dismiss('loading')
            setLoadingDismissTimeout(timeoutId => {
                if (timeoutId) {
                    clearTimeout(timeoutId)
                }
                return undefined
            })
        }
    }, [isLoading])

    return null
}

export default LoadingToast
